import type { GetStaticProps } from 'next';
import type { Hero } from 'types';
import type { FrontPage } from 'lib/queries/pages';
import React from 'react';
import { Paragraph, Wrap, ButtonClose } from '@leafwell/components';
import Image from 'next/image';
import Blocks from 'components/blocks';
import Figure from 'components/figure';
import PageWrap from 'components/page-wrap';
import StateSelect from 'components/state-select';
import PresenceJson from 'components/structured-data/presence';
import { getFrontPage } from 'lib/queries/pages';
import { useCurrentState } from 'hooks/useCurrentState';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useEventsTracker } from 'contexts/eventsTracker';
import HeadingBody from 'components/heading-body';
//import TaxSavingsCalculator from 'components/tax-savings-calculator';

const SlideUpMessage: React.FC<{
  visible: boolean;
  toggleMessage: (toggle: boolean) => void;
}> = ({ visible, toggleMessage }) => {
  const stateCode = useCurrentState();
  const { cardPage, statePages } = useWordPressContext();
  const { trackEvent } = useEventsTracker();
  const hasStateCode = stateCode && stateCode !== '';
  let url: string;
  let price: string;

  if (hasStateCode) {
    const statePage = statePages.find(({ code }) => code === stateCode);
    url = statePage.uri;

    if (statePage.price) {
      price = statePage.price;
    }
  } else {
    url = cardPage?.uri;
  }

  function handleClick() {
    trackEvent({
      event: 'select_content',
      content_type: 'limited_time_offer',
      item_id: 'page',
      origin: 'homepage',
    });
  }

  return (
    <div
      className={[
        'relative md:fixed md:bottom-8 md:rounded-lg bg-[#FFB800] z-10 transition-all duration-500',
        visible
          ? 'max-h-80 md:max-h-full right-0 md:right-8'
          : 'max-h-0 md:max-h-full md:-right-8 opacity-0',
      ].join(' ')}
    >
      <div className="flex justify-between items-start py-6 px-6 md:gap-x-8">
        <div className="flex flex-col">
          <p className="text-xl font-medium" suppressHydrationWarning>
            {price ? `Get approved for just $${price}` : 'Get approved today'}
          </p>
          <p className="text-xl">Limited offer</p>
          <a
            href={url}
            className="mt-4 relative select-none cursor-pointer whitespace-nowrap inline-grid gap-x-2 items-center justify-center text-center text-sm font-medium outline-none hover:underline leading-none border rounded-md bg-white min-h-50 p-4 w-fit"
            onClick={handleClick}
          >
            Get your card now
          </a>
        </div>
        <Image src="promotion.svg" alt="" width={126} height={118} />
        <ButtonClose
          onClick={() => toggleMessage(false)}
          className="w-6 mt-2 absolute top-4 right-2"
          size={12}
        />
      </div>
    </div>
  );
};

const HomeHero: React.FC<Hero> = ({ image, title, steps }) => {
  const [showMessage, setShowMessage] = React.useState(true);
  return (
    <>
      <SlideUpMessage visible={showMessage} toggleMessage={setShowMessage} />
      {/* <TaxSavingsCalculator /> */}
      <section className="relative bg-gradient-to-r from-gray-200 via-gray-200 to-lilac-100 xl:-mt-40 xl:pt-40 before:absolute before:md:w-3/4 before:h-full before:md:right-0 before:md:bottom-0 before:bg-gradient-to-b before:from-lilac-100 before:to-green-200 before:gradient-mask-l-0">
        <Wrap
          spacer={false}
          className="lg:grid lg:grid-cols-3 lg:gap-x-3 pt-6 xl:pt-0 pb-12 lg:pb-0 relative lg:min-h-[540px] xl:min-h-[580px] transition-all"
        >
          <div className="lg:self-center grid gap-y-6 xl:gap-y-12 lg:pb-12 col-span-2">
            <div>
              <HeadingBody level="1" tagName="h1" title={title} />
            </div>
            <StateSelect showLoginCta={true} />
            {steps && (
              <ul className="grid sm:grid-cols-2 gap-x-6 gap-y-6 xl:pt-3">
                {steps.map(({ title, description }, key) => (
                  <li
                    key={key}
                    className="grid grid-cols-2-auto gap-x-3 gap-y-1 items-start"
                  >
                    <Image
                      height={20}
                      width={20}
                      priority={true}
                      src="/check-gradient.svg"
                      alt=""
                      className="row-span-2 mt-list-offset"
                    />
                    <Paragraph
                      size="large"
                      className="font-medium"
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <Paragraph
                      size="small"
                      variant="medium"
                      dangerouslySetInnerHTML={{ __html: description }}
                      tagName="article"
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
          {image && (
            <Figure
              alt={image.alt}
              src={image.src}
              width={image.width}
              height={image.height}
              priority={true}
              className="hidden lg:block lg:self-end"
              sizes="(max-width: 1023px) 0vw, (max-width: 1200px) 33vw, 33vw"
            />
          )}
        </Wrap>
      </section>
    </>
  );
};
const Home: React.FC<FrontPage> = ({
  page: {
    title,
    seo,
    acf: { pageType, blocks, hero },
  },
  partnersReviews,
}) => {
  return (
    <PageWrap seo={seo} title={title} pageType={pageType}>
      <PresenceJson />
      <HomeHero {...hero} />
      <Blocks
        blocks={blocks}
        pageType={pageType}
        partnersReviews={partnersReviews}
      />
    </PageWrap>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  return await getFrontPage(context);
};

export default Home;
